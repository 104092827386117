import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import MapIcon from '@mui/icons-material/Map';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ReactGA from "react-ga4";
import NightlifeIcon from '@mui/icons-material/Nightlife';
import AttractionsIcon from '@mui/icons-material/Attractions';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HikingIcon from '@mui/icons-material/Hiking';

function RecommendationCard(props) {

    const category = { Drink : <NightlifeIcon/>,
         Attractions: <AttractionsIcon />,
         Food: <RestaurantIcon />,
         Trail: <HikingIcon />
    }

    return (

        <Card sx={{ maxWidth: 350 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {category[props.category]}
                    </Avatar>
                }

                title={props.title}
                subheader={props.location}
            />

            <CardMedia
                component="img"
                height="230"
                image={props.image}
                alt={props.id}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {props.description}
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
                <Tooltip title="Open web" placement="bottom">
                    <IconButton onClick={() => {
                        window.open(props.webLink)

                        ReactGA.event({
                            category: 'User',
                            action: 'openweb_' + props.shortTitle
                        })

                    }
                    }>
                        <LanguageIcon fontSize="large"/>              
                              </IconButton>
                </Tooltip>
                <Tooltip title="Google Map" placement="bottom">

                    <IconButton onClick={() => {

                        ReactGA.event({
                            category: 'User',
                            action: 'googlemap_' + props.shortTitle
                        })
                        window.open(props.mapLink)
                    }}>
                        <MapIcon fontSize="large" />
                    </IconButton>
                </Tooltip>

            </CardActions>

        </Card>
    );
}

export default RecommendationCard;