import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import BookingLinks from './BookingLinks';
import { Typography, CardMedia, Card, Box } from '@mui/material';


function PropertyCard({ property }) {

    let navigate = useNavigate();

    const handleClickOnImage = (id) => {
        console.log(handleClickOnImage)
        navigate(`/property/${id}`);
    }

    const handleClickProfile = () => {

        ReactGA.event({
            category: 'User',
            action: 'profile_page'
        })

        navigate('/profile');
    }

    return (
        <>
            <Card sx={{ maxWidth: 340 }}>


                <CardHeader
                    avatar={
                        <Tooltip title="profile" placement="left">
                            <IconButton onClick={handleClickProfile}>
                                <Avatar sx={{ width: 65, height: 65 }} alt="Julia & Simon" src="/Avatar.png" />
                            </IconButton>
                        </Tooltip>
                    }
                    title={property.title}
                    subheader={property.location}
                />

                <Box
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={() => handleClickOnImage(property.id)}
                    title="Click to view more details"
                >

                    <CardMedia
                        component="img"
                        height="260"
                        image={property.image}
                        alt="image"
                        onClick={() => handleClickOnImage(property.id)}
                    />


                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {property.description}
                        </Typography>
                    </CardContent>

                    </Box>

                <BookingLinks property={property} />
            </Card>
        </>
    )
}

export default PropertyCard;