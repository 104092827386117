import React from 'react';
import { CardActions, Tooltip, IconButton } from '@mui/material';
import ReactGA from "react-ga4";


function BookingLinks({ property }) {

    return (
        <CardActions disableSpacing>
            Book here via
            <Tooltip title="Open Airbnb" placement="bottom">
                <IconButton onClick={() => {
                    window.open(property.airbnbLink);

                    ReactGA.event({
                        category: 'User',
                        action: 'airbnb_' + property.id
                    });
                }}>
                    <img src="/airbnb-24.svg" height={35} width={35} alt='airbnb button' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Open Vrbo" placement="bottom">
                <IconButton onClick={() => {
                    ReactGA.event({
                        category: 'User',
                        action: 'vrbo_' + property.id
                    });
                    window.open(property.vrboLink);
                }}>
                    <img src="/vrbo.svg" height={50} width={65} alt='vrbo button' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Open Booking.com" placement="bottom">
                <IconButton onClick={() => {
                    ReactGA.event({
                        category: 'User',
                        action: 'booking_' + property.id
                    });
                    window.open(property.bookingLink);
                }}>
                    <img src="/booking.png" height={40} width={60} alt='booking.com button' />
                </IconButton>
            </Tooltip>
        </CardActions>
    );
}

export default BookingLinks;
