import { Outlet } from "react-router-dom";
import Header from '../../components/header/header';
import Footer from '../../components/Footer/footer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ReactGA from "react-ga4";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ContactusModal from '../../components/ContactUs/ContactusModal';
import FacebookIcon from '@mui/icons-material/Facebook';

function RootLayout() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <ContactusModal open={open} handleOpen={handleOpen} handleClose={handleClose} />
            <Header />
            <Outlet />
            <Grid sx={{ mt: 1, mb: 4 }} container spacing={2} minHeight={160} >

                <Grid sx={{ mb: 1 }} xs={12} display="flex" justifyContent="center" alignItems="center">


                    Contact us via
                    <Tooltip title="Contact Us" placement="bottom">

                        <IconButton onClick={() => {
                            ReactGA.event({
                                category: 'User',
                                action: 'contactus'
                            })

                            setOpen(true);

                        }}>
                            <EmailOutlinedIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Facebook" placement="bottom">

                        <IconButton onClick={() => {
                            ReactGA.event({
                                category: 'User',
                                action: 'facebook'
                            })

                            window.open('https://www.facebook.com/mhome4me')

                        }}>
                            <FacebookIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>


                </Grid>

                <Grid sx={{ mt: 1, mb: 4 }} xs={6} display="flex" justifyContent="center" alignItems="center">

                    <IconButton onClick={() => {

                        ReactGA.event({
                            category: 'User',
                            action: 'VRBO_SUPERHOST_ICON'
                        })
                        window.open('https://www.vrbo.com/3166213?unitId=3739306')
                    }}>
                        <img src="/vrbo-premier-host.png" height={55} width={140} title="vrbo-premier-host" alt="vrbo-premier-host" />
                    </IconButton>

                </Grid>

                <Grid sx={{ mt: 1, mb: 4 }} xs={6} display="flex" justifyContent="center" alignItems="center">

                    <IconButton onClick={() => {

                        ReactGA.event({
                            category: 'User',
                            action: 'AIRBNB_SUPERHOST_ICON'
                        })
                        window.open('https://www.airbnb.com/users/show/436328576')
                    }}>
                        <img src="/airbnb-super-host.png" height={60} width={140} title="airbnb super host" alt="airbnb super host" />
                    </IconButton>
                </Grid>

            </Grid>

            <Footer />
        </>
    )
}

export default RootLayout;