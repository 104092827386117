import Typography from '@mui/material/Typography';
import PropertyCard from '../property/propertyCard';
import Grid from '@mui/material/Grid';

function PropertyList(props) {


   
    return (
        <>
            <Typography variant="h5" align="center" mt={4}>
                {props.title}
            </Typography>

            <Grid sx={{ mt: 4, mb: 2 }} container spacing={2} minHeight={160} >

                {props.propertyData.map((item) => (

                    <Grid key={item.id} xs={12} sx={{ mt: 1, mb: 4 }} display="flex" justifyContent="center" alignItems="center">
                        <PropertyCard property={item} />
                    </Grid>
                    
                ))}

            </Grid>
        </>
    );
}

export default PropertyList;