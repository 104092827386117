import React from "react";
import Typography from '@mui/material/Typography';
import { CardMedia, Card, Grid } from '@mui/material';

const description =
    "Welcome to our family's profile! We are a family who love exploring new destinations and embarking on exciting adventures.\n As passionate travelers ourselves, we often choose the comfort of Airbnb accommodations. And now, we are thrilled to share our charming mountain house in North Carolina with fellow vacation enthusiasts like you"
    + "Nestled in the breathtaking beauty of the mountains, our cozy cabin offers a serene and picturesque retreat. We have personally taken charge of managing and assisting guests to ensure a personalized experience. Every detail of our mountain house has been carefully curated to strike the perfect balance between comfort and adventure. Whether you're seeking a peaceful vacation or an action-packed getaway, our cabin provides an ideal haven for your North Carolina escape."
    + "Having been Airbnb guests on numerous occasions, we understand the significance of a relaxing and enjoyable stay. That's why we are committed to being hands-on hosts, ready to assist with any request or question you may have. From cozy furnishings to modern amenities, we have thoughtfully prepared our cabin to provide a delightful experience, allowing you to unwind and create lasting memories."
    + "One of the exceptional features of our mountain house is its proximity to breathtaking natural landscapes and thrilling outdoor activities. Surrounded by enchanting hiking trails, cascading waterfalls, and stunning vistas, adventure awaits just outside your door. If you're an adventure seeker, you'll be delighted by the plethora of options available, including mountain biking, rock climbing, kayaking, and skiing. Alternatively, if you prefer a serene and tranquil experience, you can simply immerse yourself in the beauty of nature while relaxing on the porch of our mountain house."
    + "We believe that open communication is key to ensuring a seamless and enjoyable vacation. As hands-on hosts, we are personally available to manage and help with any request or question you may have during your stay. Feel free to reach out to us at any time. We are dedicated to making your North Carolina vacation exceptional and exceeding your expectations."
    + "Thank you for considering our mountain house for your next getaway. We are genuinely excited to have the opportunity to host you and share our love for travel, adventure, and the beauty of North Carolina. Get ready for an unforgettable vacation filled with breathtaking scenery, thrilling activities, and our personal attention to ensure your stay is relaxing and enjoyable. We hope that your time in our mountain house will be a memorable experience, leaving you with cherished memories and a desire to return again and again.";

function HostProfile(props) {

    return (
        <>

            <Grid sx={{ mt: 8, mb: 4 }} container spacing={2} minHeight={160} >

                <Grid xs={12} sx={{ mt: 1, mb: 4 }} display="flex" justifyContent="center" alignItems="center">
                    <Card sx={{
                        width: 550,
                        height: 300

                    }}>

                        <CardMedia
                            component="img"

                            image="/Avatar.png"
                            alt="image"
                        />

                    </Card>                    </Grid>


            </Grid>

            <Typography sx={{ mt: 3, ml: 3, mr: 3, mb: 1 }} variant="h4" align="center" mt={4}>
                     About Us
            </Typography>

            
            <Typography sx={{ mt: 3, ml: 3, mr: 3, mb: 8 }} variant="subtitle1" align="justify" mt={4}>
                {description}
            </Typography>
        </>
    );
}

export default HostProfile;