import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { Toolbar, Typography, Box } from '@mui/material';
import LandscapeIcon from '@mui/icons-material/Landscape';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";


const logo = "MHOME4ME"

function Header() {

    let navigate = useNavigate();

    const handleNavigation = (e) => {

        ReactGA.event({
            category: 'User',
            action: 'navigation_button:' + e.currentTarget.getAttribute("data-value")
        })
        navigate(e.currentTarget.getAttribute("data-value"));
    }

    return (
        <AppBar position="static" enableColorOnDark={true}>
            <Container maxWidth="sx">

                <Toolbar disableGutters>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            mr: 1,
                            flexGrow: 1
                        }}
                        onClick={handleNavigation}
                    >
                        <LandscapeIcon sx={{ mr: 1, fontSize: 35 }} />

                        <Typography
                            variant="subtitle1"
                            noWrap
                            component="a"
                            sx={{
                                mr: 1,
                                fontFamily: 'monospace',
                                fontWeight: 600,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                flexGrow: 1
                            }}
                        >
                            {logo}
                        </Typography>
                    </Box>
                    <Button data-value="/" onClick={handleNavigation} size="small" color="inherit">Home</Button>
                    <Button data-value="/guidebook" onClick={handleNavigation} size="small" color="inherit">GUIDEBOOK</Button>

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;