import './App.css';

import PropertyList from './components/home/properyList';
import ReactGA from "react-ga4";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HostProfile from './pages/profile/hostProfile';
import RootLayout from './pages/rootLayout/RootLayout';
import GuideBook from './pages/Guidebook/GuideBook';
import propertiesList from './data/propertiesList.json';
import guidebookList from './data/guidebookList.json';
import PropertyProfile from './pages/PropertyProfile/PropertyProfile';

const title = "Explore Good Places to Stay - Hosted by Super Host Julia And Simon"

function App() {


  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        { path: '/', element: <PropertyList title={title} propertyData={propertiesList} /> },
        { path: '/home', element: <PropertyList title={title} propertyData={propertiesList} /> },
        { path: '/profile', element: <HostProfile title='title' /> },
        { path: '/guidebook', element: <GuideBook title='Julia & Simon - Food & Fun Things To Do In NC Mountain Area' guideBookData={guidebookList} /> },
        { path: '/property/:id', element: <PropertyProfile properties={propertiesList} /> }
      ]
    }
  ])

  ReactGA.initialize('G-40P74HDJZE');
  return (
    <>
      <RouterProvider router={router} />

    </>
  );

}

export default App;
