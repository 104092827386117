import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Button, Box, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import propertiesList from '../../data/propertiesList.json'
import BookingLinks from '../../components/property/BookingLinks';
import { listObjects } from '../../service/imagesService';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function PropertyProfile(props) {

    const { id } = useParams();
    const [imageUrls, setImageUrls] = useState([props.image]);
    const property = propertiesList.find(prop => prop.id === id);

    useEffect(() => {
        const fetchImages = async () => {
          const urls = await listObjects(id);
          setImageUrls( [property.image, ...urls]);
        } ;
        fetchImages();
    }, [id, property.image]);


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
    };

    const handleNextImage = () => {

        setCurrentImageIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
    };
    if (!property) {
        return <Typography variant="h6">Property not found</Typography>;
    }

    const formatDescription = (description) => {
        return { __html: description.replace(/\n/g, '<br />') };
    };


    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
                <Typography variant="h5" component="h2">
                    {property.title}
                </Typography>
                <Box>
                    <Button startIcon={<ShareIcon />}>Share</Button>
                    { /* 
                  <Button startIcon={<FavoriteIcon />}>Save</Button>
                  */ }
                </Box>
            </Box>
            <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                <IconButton onClick={handlePreviousImage} sx={{ position: 'absolute', left: 0 }}>
                    <ArrowBackIosIcon />
                </IconButton>

                <img 
                
                    src={imageUrls[currentImageIndex]} 
                    alt={property.title} 
                    style={{ width: '100%', height: 'auto', maxWidth: '800px', maxHeight: '600px', marginBottom: '20px' }} 
                />
                <IconButton onClick={handleNextImage} sx={{ position: 'absolute', right: 0 }}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>

            <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                About this space
            </Typography>
            <Typography variant="body1" component="p">
                {property.listingDescription}
            </Typography>
            <Typography variant="h6" component="h4" sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                The space
            </Typography>

            <Typography variant="body1" component="p" dangerouslySetInnerHTML={formatDescription(property.spaceDescription)}>
            </Typography>
               
            <Box mt={2}>
                <BookingLinks property={property} />
            </Box>

        </Container>
    );
}

export default PropertyProfile;