import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import RecommendationCard from './RecommendationCard';
import MultipleSelectChip from './SelectCategory';
import { useState } from 'react';


function GuideBook(props) {

    const [filter, setFilter] = useState([]);


    return (
        <>
            <Typography variant="h5" align="center" mt={4} ml={1} mr={1}>
                {props.title} 
            </Typography>

          
            <Grid sx={{ mt: 4, mb: 2 }} container spacing={2} minHeight={160} >

            <Grid  xs={12} sx={{ mt: 1, mb: 1 }} display="flex" justifyContent="center" alignItems="center">
                <MultipleSelectChip setFilter={setFilter}/>
            </Grid>
                {props.guideBookData.filter(item => {
                        if (filter.length === 0) {
                            return true;
                        } 
                        return filter.includes(item.category)


                }).map((item) => (

                    <Grid key={item.id} xs={12} sx={{ mt: 1, mb: 4 }} display="flex" justifyContent="center" alignItems="center">
                        <RecommendationCard category={item.category} avatar={item.avatar} shortTitle={item.id} title={item.title} description={item.description} location={item.location} webLink={item.webLink} mapLink={item.mapLink} image={item.image} />
                    </Grid>

                ))}

            </Grid>
        </>
    )
}

export default GuideBook;