import emailjs from '@emailjs/browser';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function Contactus(props) {

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)

    const [fullName, setFullName] = useState("")
    const [fullNameError, setfullNameError] = useState(false)

    const [fullMessage, setFullMessage] = useState("")
    const [fullMessageError, setfullMessageError] = useState(false)


    const [alert, setAlert] = useState(false);
    const [sendingProgress, setsSndingProgress] = useState(false);


    const sendEmail = (e) => {

        e.preventDefault();
        setsSndingProgress(true);

        setEmailError(false)
        setfullNameError(false)
        setfullMessageError(false)

        if (email === '') {
            setEmailError(true)
        }
        if (fullName === '') {
            setfullNameError(true)
        }
        if (fullMessage === '') {
            fullMessageError(true);
        }

        const templateParams = {
            reply_to: email,
            from_name: fullName,
            message: fullMessage,
            shortTitle: props.shortTitle
        }

        emailjs.send('service_rxsm3he', 'template_8fjjh5o', templateParams, 'BR2YWqj5wFIarxkFc')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            }).finally(() => {
                setFullMessage("")
                setFullName("")
                setEmail("")
                setAlert(true);
                setsSndingProgress(false);


                setTimeout(() => {
                    props.handleClose(true)
                    setAlert(false);
                }, 3000);
                //   
            });


    };

    return (

        <>
            {alert &&

                <>
                    <Alert severity="success">Email has been sent — We'll contact you shortly!</Alert>
                    <br />
                </>
            }

            <Typography id="modal-modal-title" variant="h6" component="h2">
                Contact Simon & Julia
            </Typography>
       
            <Typography variant='caption' >
                Typically responds within an hour
            </Typography>

            <Typography sx={{ mt: 3, mb: 3 }} variant='subtitle2' >
                Message the Host:
            </Typography>

            <form autoComplete="off" onSubmit={sendEmail}>
                <TextField
                    label="Full Name"
                    onChange={e => setFullName(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={fullName}
                    error={fullNameError}
                />

                <TextField
                    label="Email"
                    onChange={e => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3 }}
                    fullWidth

                    value={email}
                    error={emailError}
                />

                <TextareaAutosize
                    aria-label="minimum height"
                    style={{
                        width: "100%",
                        fontFamily: 'IBM Plex Sans, sans-serif'


                    }}
                    required
                    minRows={5}
                    placeholder="Message"
                    onChange={e => setFullMessage(e.target.value)}
                    value={fullMessage}
                />

                <Button type="submit" sx={{ mt: 3 }} variant="outlined">Send message</Button>
            </form>

            {sendingProgress &&
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
        </>
    );

}

export default Contactus;