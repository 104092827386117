// Define the bucket URL
const bucketUrl = 'https://property-unit-images.s3.amazonaws.com';

// Function to list objects in the S3 bucket
export async function listObjects(folder = '') {
    try {
        const response = await fetch(`${bucketUrl}?list-type=2&prefix=${folder}`);
        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, 'text/xml');
        const keys = Array.from(xmlDoc.getElementsByTagName('Key'));

        // List of image file extensions
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];

        // return keys.map(key => `${bucketUrl}/${key.textContent}`);
        const imageUrls = keys
            .map(key => key.textContent)
            .filter(key => {
                const extension = key.split('.').pop().toLowerCase();
                return imageExtensions.includes(extension);
            })
            .map(key => `${bucketUrl}/${key}`);

        return imageUrls;
    } catch (err) {
        console.error('Error fetching object list:', err);
        return [];
    }
}

