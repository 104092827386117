import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import Contactus from "./Contactus";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 34,
    p: 4,
};

function ContactusModal(props) {


    return (
    <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Contactus handleClose={props.handleClose} />
        </Box>
    </Modal>);
}

export default ContactusModal;